import React from "react"
import Layout, { SimpleLayout } from "../components/layout"
import Hero from "../components/hero"

export default () => (
  <Layout>
    <Hero center hero="Terms & Conditions" herosub="" />
    <SimpleLayout
      style={{
        background: "white",
      }}
    >

<p><strong>END USER LICENSE AGREEMENT</strong></p>
<p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF THIS end user LICENSE AGREEMENT</p>
<p>(&ldquo;AGREEMENT&rdquo;) CAREFULLY BEFORE DOWNLOADING, INSTALLING OR USING each app created by DUAL GOOD HEALTH (defined below). THIS IS A LEGAL AGREEMENT BETWEEN YOU AND DUAL GOOD HEALTH LIMITED. (&ldquo;DUAL GOOD HEALTH&rdquo;). THE TERMS AND CONDITIONS OF THIS AGREEMENT, GOVERN YOUR ACCESS TO AND USE OF THE APP.</p>
<p><strong>Dual Good Health is willing to license the App to you only upon the condition that you accept all the terms contained in this Agreement. By clicking &ldquo;I accept&rdquo; or by downloading, installing or using the App, you have indicated that you understand this Agreement and accept all of its terms. If you are accepting the terms of this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to the terms of this Agreement, and, in such event, &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer to that company or other legal entity. If you do not accept all the terms of this Agreement, then Dual Good Health is unwilling to license the App to you, and you must return the App to Dual Good Health for a full refund, if you have paid for the license to the App, or, if Dual Good Health has made the App available to you without charge, you must destroy all copies of the App.&nbsp;</strong></p>
<p><strong>Your right to return the App for a refund expires 30 days after the date of purchase.</strong></p>
<p><strong>Health and Safety Notice:</strong> Read and follow all setup and operating instructions and safety warnings provided with the virtual reality equipment you are using. Content available for viewing through use of the App (&ldquo;<strong>VR Content</strong>&rdquo;) produces an immersive virtual reality experience, and users may have reactions to that experience, including simulation sickness, nausea discomfort, eye strain or disorientation. These reactions may be triggered when viewing VR Content for a brief or sustained period of time. Immediately stop viewing the VR Content if you experience any of these symptoms. Do not drive or operate machinery until you have recovered from any symptoms you experienced. See a doctor before viewing VR Content if you have a history of experiencing these symptoms or if you are prone to seizures. Anyone viewing VR Content should take frequent breaks while doing so. Remain seated whenever possible when viewing VR Content and take special care to be aware of your surroundings to ensure you do not injure yourself or other people around you while you are viewing or immediately after Viewing VR Content. Viewing VR Content is not recommended for children without adult supervision.</p>
<p>As used herein: &ldquo;<strong>App</strong>&rdquo; means the virtual reality content viewing applications offered by Dual Good Health, (including any updates, new versions or new releases thereof that may be provided to you by Dual God Health), any content available through use of such applications or any accompanying documentation.</p>
<ol>
<li><strong> Grant of License.</strong> Conditioned upon your compliance with the terms and conditions of this Agreement, Dual Good Health grants you a non-exclusive and non-transferable license for you to Execute (as defined herein) the executable form of the App on hardware devices that you own or control solely for your personal and commercial purposes solely in relation to medical training and education. For purposes of this Agreement, &ldquo;<strong>Execute</strong>&rdquo; and &ldquo;<strong>Execution</strong>&rdquo; means to load, install, and run the App in order to benefit from its functionality as designed by Dual Good Health. Your rights in the App will be limited to those expressly granted in this Agreement. Dual Good Health reserves all rights in the App not expressly granted to you in this Agreement.&nbsp;</li>
<li><strong> Restrictions.</strong> Except as expressly specified in this Agreement, you may not: (a) copy (except in the course of loading or installing) or modify the App, including but not limited to adding new features or otherwise making adaptations that alter the functioning of the App; (b) transfer, sublicense, lease, lend, rent or otherwise distribute the App to any third party; or (c) make the functionality of the App available to multiple users through any means, including but not limited to by uploading the App to a network or file-sharing service or through any hosting, application services provider, service bureau, software-as-a-service (SaaS) or any other type of services. You acknowledge and agree that portions of the App, including but not limited to the source code and the specific design and structure of individual modules or programs, constitute or contain trade secrets of Dual Good Health and its licensors. Accordingly, you agree not to disassemble, decompile or reverse engineer the App, in whole or in part, or permit or authorize a third party to do so, except to the extent such activities are expressly permitted by law notwithstanding this prohibition.</li>
<li><strong> Ownership.</strong> The copy of the App is licensed, not sold. Dual Good Health retains ownership of the copy of the App itself, including all intellectual property rights therein. The App is protected by UK copyright law and international treaties. You will not delete or in any manner alter the copyright, trademark, and other proprietary rights notices or markings appearing on the App as delivered to you.&nbsp;</li>
<li><strong> Privacy.</strong> Please refer to our Privacy Policy (http://dualgoodhealth.com/privacy-policy/) for information on how we collect, use and disclose information from our users. You acknowledge and agree that your use of the App is subject to our Privacy Policy.</li>
<li><strong> Feedback.</strong> We encourage and invite you to provide Dual Good Health with feedback and comments and suggestions for improvements to the App (collectively, &ldquo;<strong>Feedback</strong>&rdquo;). As part of providing Dual Good Health with such Feedback, you agree to complete any surveys or other questionnaires that Dual Good Health may send to you from time to time regarding your experience in using the App. You acknowledge and agree that all Feedback will be the sole and exclusive property of Dual Good Health. You hereby assign to Dual Good Health and agree to assign to Dual Good Health all of your right, title and interest in and to all Feedback, including all intellectual property rights therein. At Dual Good Health&rsquo;s request and expense, you will execute documents and take such further acts as Dual Good Health may reasonably request to assist Dual Good Health to acquire, perfect and maintain its intellectual property rights and other legal protections for the Feedback.</li>
<li><strong> Term and Termination.</strong> The license granted under this Agreement remains in effect for a period of 1 year, unless earlier terminated in accordance with this Agreement. You may terminate the license at any time by destroying all copies of the App in your possession or control. Dual Good Health may terminate the license granted under this Agreement, at our sole discretion, at any time and without notice to you. Without limiting the foregoing, the license granted under this Agreement will automatically terminate, with or without notice from Dual Good Health, if you breach any term of this Agreement. Upon termination, you must at Dual Good Health&rsquo;s option either promptly destroy or return to Dual Good Health all copies of the App in your possession or control.</li>
<li><strong> Updates and Upgrades; No Obligation</strong><strong><em>.</em></strong> Dual Good Health is not obligated to maintain or support the App, or to provide you with updates, upgrades or services related thereto. You acknowledge that Dual Good Health may from time to time in its sole discretion issue updates or upgrades to the App. You consent to such updating or upgrading, and agree that the terms and conditions of this Agreement will apply to all such updates or upgrades.</li>
<li><strong> Compliance with Laws.</strong> You agree to comply with all applicable laws, rules and regulations when using the App. You will not use the App to infringe or misappropriate any third party&rsquo;s intellectual property rights.</li>
<li><strong> No Warranty.</strong> THE APP IS PROVIDED &ldquo;AS IS&rdquo;, WITHOUT WARRANTY OF ANY KIND. DUAL GOOD HEALTH DISCLAIMS ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT, AND ANY WARRANTIES AND CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM DUAL GOOD HEALTH OR ELSEWHERE WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED IN THIS AGREEMENT.</li>
<li><strong> Limitation of Liability.</strong> YOU ACKNOWLEDGE AND AGREE THAT, AS BETWEEN YOU AND DUAL GOOD HEALTH, TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU ASSUME THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE APP AND THE VIEWING OF VR CONTENT. DUAL GOOD HEALTH is NOT responsible for, and expressly disclaims any liability for, any personal injury, damage or harm resulting from your USE OF THE APP AND THE VIEWING OF VR CONTENT. DUAL GOOD HEALTH&rsquo;S TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE LIMITED TO THE AMOUNTS PAID TO DUAL GOOD HEALTH BY YOU FOR THE APP OR, IN THE EVENT THAT DUAL GOOD HEALTH HAS MADE THE APP AVAILABLE TO YOU WITHOUT CHARGE, DUAL GOOD HEALTH&rsquo;S TOTAL LIABILITY WILL BE LIMITED TO &pound;100. IN NO EVENT WILL DUAL GOOD HEALTH BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, BUSINESS, PROFITS OR ABILITY TO EXECUTE) OR FOR the cost of procuring substitute products ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE EXECUTION OR PERFORMANCE OF THE APP, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT DUAL GOOD HEALTH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.</li>
<li><strong> Export Law. </strong>You agree to comply fully with all UK export laws and regulations to ensure that neither the App nor any technical data related thereto nor any direct product thereof are exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations.</li>
<li><strong> Content.</strong> You acknowledge that the content of the app has been created by the usage of third party websites, resources, content or information &ldquo;<strong>Third Party Materials</strong>&rdquo;. You acknowledge that the &ldquo;<strong>Content</strong>&rdquo;, of the app is serving for information purposes only and is not meant to replace a full certified training course. Without limiting any other terms of this Agreement, you acknowledge and agree that Dual Good Health: (a) is not responsible for the availability or accuracy of such Third Party Materials; and (b) has no liability to you or any third party for any harms, injuries or losses suffered as a result of usage of the App&rsquo;s Content.</li>
<li><strong> Third Party Hardware and Software. </strong>You acknowledge and agree that Dual Good Health is not responsible for any hardware or other software required to access and use the App, including, without limitation, any loss or corruption of data or any other loss or damage of any kind arising from your use of such hardware or software.</li>
<li><strong> Changes to Terms or App. </strong>We may modify this Agreement at any time, in our sole discretion. If we do so, we&rsquo;ll let you know either by posting the modified terms of the Agreement on the App or through other communications. It&rsquo;s important that you review the terms of this Agreement whenever we modify them because if you continue to use the App after we have posted the modified terms on the App, you are indicating to us that you agree to be bound by the modified terms. If you don&rsquo;t agree to be bound by the modified terms, then you may not use the App anymore. Because the App and the services available through the App are evolving over time we may change or discontinue all or any part of the App, at any time and without notice, at our sole discretion.</li>
<li><strong> General.</strong> This Agreement will be governed by and construed in accordance with UK laws. You may not assign or transfer this Agreement or any rights granted hereunder, by operation of law or otherwise, without Dual Good Health&rsquo;s prior written consent, and any attempt by you to do so, without such consent, will be void. Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other remedies under this Agreement or otherwise. The failure by either party to enforce any provision of this Agreement will not constitute a waiver of future enforcement of that or any other provision. Any waiver, modification or amendment of any provision of this Agreement will be effective only if in writing and signed by authorized representatives of both parties. If any provision of this Agreement is held to be unenforceable or invalid, that provision will be enforced to the maximum extent possible, and the other provisions will remain in full force and effect. This Agreement is the complete and exclusive understanding and agreement between the parties regarding its subject matter, and supersedes all proposals, understandings or communications between the parties, oral or written, regarding its subject matter, unless you and Dual Good Health have executed a separate agreement. Any terms or conditions contained in your purchase order, licence agreement or other ordering document (if such document exists) that are inconsistent with or in addition to the terms and conditions of this Agreement are hereby rejected by Dual Good Health and will be deemed null.</li>
<li><strong> Notice.</strong> All notices or approvals required or permitted under this Agreement will be in writing and delivered by confirmed facsimile transmission, by overnight delivery service, or by certified mail, and in each instance will be deemed given upon receipt. All notices or approvals will be sent by Dual Good Health to you to the address you provide to us, including email addresses. All notices will be sent by you to Dual Good Health to the address set forth below.</li>
<li><strong> Contact Information.</strong> If you have any questions regarding this Agreement, you may contact Dual Good Health at <a href="mailto:hello@dualgoodhealth.com">hello@dualgoodhealth.com</a>.</li>
</ol>


    </SimpleLayout>
  </Layout>)